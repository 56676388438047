.aside{
  width: 264px;
  min-width: 264px;
  background: #F6F6F6;
  padding: 43px 0 20px 20px;
  &__list{
    margin: 0;
    padding: 0;
    list-style-type: none;
    li{
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  &__link{
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 4px 10px 4px 10px;
    div{
      margin-left: 10px;
      font-size: 16px;
      line-height: 130%;
      color: #2E2E2E;
    }
    &_active{
      border-left: 4px solid #3171A3;
      background: #0091D2;
      div{
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        color: #FFFFFF;
      }
    }
    &:active,
    &:focus{
      text-decoration: none;
      color: #2E2E2E;
    }
  }
}

@media screen and (max-width: 991px){
  .aside{
    padding: 13px 0;
    position: fixed;
    z-index: 3;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #FFFFFF;
    box-shadow: 0px -4px 14px rgba(0, 0, 0, 0.1);
    &__list{
      display: flex;
      align-items: center;
      justify-content: space-around;
      li{
        margin-bottom: 0;
        min-width: 44px;
        width: 44px;
        min-height: 44px;
        height: 44px;
      }
    }
    &__link{
      div{
        display: none;
      }
      &_active{
        border-radius: 50%;
        border-left: none;
        min-width: 44px;
        width: 44px;
        min-height: 44px;
        height: 44px;
      }
    }
  }
}