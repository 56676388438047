.benefit{
  // width: calc(33.3% - 15px);
  width: 100%;
  &__icon{
    width: 101px;
    height: 101px;
    background: #0091D2;
    box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__descr{
    margin-top: 16px;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #2E2E2E;
    max-width: 301px;
  }
}


@media screen and (max-width: 768px){
  .benefit{
    width: 100%;
    margin-bottom: 32px;
    &__icon{
      width: 70px;
      height: 70px;
      img{
        width: 50%;
      }
    }
    &__descr{
      max-width: 100%;
      font-size: 16px;
    }
  }
}