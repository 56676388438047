.certificate{
  padding: 19px 126px 273px 21px;
  &__title{
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #0091D2;
    margin: 20px 0;
  }
  &__descr{
    font-size: 16px;
    line-height: 130%;
    color: #000000;
  }
  &__noty{
    margin: 15px 0 31px 0;
    display: inline-flex;
    align-items: center;
    padding: 12px 20px 12px 22px;
    background: #FFF1D7;
    border-left: 4px solid #E47B00;
    div{
      margin-left: 12px;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #E47B00;
    }
  }
  &__wrapper{ 
    display: flex;
    justify-content: space-between;
  }
  &__left,
  &__right{
    width: calc(50% - 10px);
  }
  &__label{
    margin-bottom: 24px;
  }
  &__left,
  &__right{
    .certificate__label:last-child{
      margin-bottom: 0;
    }
  }
  &__logo{
    &_span{
      display: block;
      padding-left: 16px;
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #2E2E2E;
    }
    &_wrapper{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    &_photo{
      width: calc(100% - 32px);
    }
    &_label{
      height: 144px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #F7F7F7;
      cursor: pointer;
      border-radius: 10px;
      input{
        position: absolute;
        z-index: -1;
        opacity: 0;
      }
    }
    &_title{
      margin-top: 9px;
      font-size: 10px;
      line-height: 120%;
      color: #AEAEAE;
    }
    &_accept{
      margin-top: 8px;
      font-size: 10px;
      line-height: 120%;
      color: #2E2E2E;
    }
    &_edit{
      border: none;
      outline: none;
      background: none;
      padding-right: 0;
      cursor: pointer;
    }
  }
  &__addCertificate{
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 215px;
    cursor: pointer;
    min-width: 215px;
    height: 48px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;
    background: #0091D2;
    box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
    border-radius: 3px;
    border: 1px solid #0091D2;
    text-decoration: none;
    div{
      margin-left: 10px;
    }
    &:active,
    &:focus{
      outline: none;
      text-decoration: none;
    }
    &_disabled{
      opacity: 0.5;
      pointer-events: none;
    }
  }
  &__form{
    &_error{
      color: red;
      font-size: 14px;
      margin-top: 14px;
    }
  }
  &__footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-top: 40px;
  }
}

@media screen and (max-width: 1200px){
  .certificate{
    padding: 19px 30px 273px 21px;
  }
}


@media screen and (max-width: 991px){
  .certificate{
    padding: 19px 30px 100px 21px;
  }
}

@media screen and (max-width: 768px){
  .certificate{
    &__title{
      font-size: 28px;
    }
    &__noty{
      width: 100%;
      div{
        font-size: 16px;
      }
    }
    &__wrapper{
      flex-direction: column;
    }
    &__left,
    &__right{
      width: 100%;
    }
    &__right{
      margin-top: 24px;
    }
  }
}

@media screen and (max-width: 576px){
  .certificate{
    padding: 19px 10px 50px 10px;
    &__wrapper{
      flex-direction: column;
    }
    &__left,
    &__right{
      width: 100%;
    }
    &__addCertificate{
      width: 100%;
    }
  }
}