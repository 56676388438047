.footer{
  background: #2E2E2E;
  padding: 38px 0 20px 0;
  &__wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &:last-child{
      align-items: center;
      margin-top: 61px;
    }
  }
  &__links{
    a{
      text-decoration: none;
    }
  }
  &__left{
    display: flex;
    align-items: flex-start;
  }
  &__address{
    margin-left: 10px;
    max-width: 261px;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
  }
  &__right{
    color: #FFFFFF;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  &__link{
    padding: 0 4px;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
    cursor: pointer;
  }
  &__socials{
    display: flex;
    justify-content: flex-end;
    margin-top: -25px;
  }
  &__social{
    margin-right: 8px;
    cursor: pointer;
    &:last-child{
      margin-right: 0;
    }
  }
  &__copy{
    font-size: 10px;
    line-height: 120%;
    color: #FFFFFF;
  }
  &__noty{
    font-size: 16px;
    line-height: 130%;
    color: #ffffff;
    span{
      color: red;
    }
  }
}

@media screen and (max-width: 991px){
  .footer{
    &__wrapper{
      flex-direction: column-reverse;
      align-items: center;
    }
    &__right{
      align-items: center;
    }
    &__left{
      margin-top: 20px;
    }
    &__socials{
      margin-top: 40px;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 768px){
  .footer{
    padding-bottom: 85px;
    &__links{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__link{
      margin-bottom: 24px;
      font-size: 16px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    &__left{
      margin-top: 45px;
      flex-direction: column;
      align-items: center;
    }
    &__address{
      margin: 10px 0 0 0;
      text-align: center;
    }
    &__wrapper{
      &:last-child{
        margin-top: 45px;
      }
    }
    &__copy{
      margin-top: 45px;
    }
    &__devider{
      display: none;
    }
  }
}