@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

*{
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
}

.container{
  max-width: 1140px;
  margin: 0 auto;
}

.container-fluid{
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.posr{
  position: relative;
}

.flex{
  display: flex;
}

.align-c{
  align-items: center;
}

.jcsb{
  justify-content: space-between;
}

@media screen and (max-width: 1200px){
  .container{
    max-width: 960px;
  }
}

@media screen and (max-width: 991px){
  .container{
    max-width: 720px;
  }
}

@media screen and (max-width: 768px){
  .container{
    max-width: 540px;
  }
  .container-fluid{
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 576px){
  .container{
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .container-fluid{
    padding-right: 10px;
    padding-left: 10px;
  }
}

