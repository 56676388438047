.dropdown{
  width: 100%;
  border-bottom: 2px solid #0091D2;
  padding: 16px;
  cursor: pointer;
  border-radius: 0px;
  transition: .2s ease-in-out;
  svg{
    transform: rotate(0deg);
    transition: .2s ease-in-out;
  }
  &__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  &__title{
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #2E2E2E;
    margin: 0;
    width: calc(100% - 40px);
  }
  &__descr{
    padding: 20px 16px;
    font-size: 16px;
    line-height: 130%;
    color: #2E2E2E;
    display: none;
  }
  &__active{
    background: #E5EEF8;
    border-radius: 10px;
    border-color: #E5EEF8;
    height: auto!important;
    transition: .2s ease-in-out;
    svg{
      transform: rotate(180deg);
      transition: .2s ease-in-out;
    }
    .dropdown__descr{
      display: block;
    }
  }
}

@media screen and (max-width: 991px){
  .dropdown{
    &__title{
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 768px){
  .dropdown{
    width: 100%;
    &__title{
      font-size: 16px;
    }
  }
}