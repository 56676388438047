.partners{
  padding: 66px 0 112px 0;
  height: calc(100vh - 285px);
  min-height: 700px;
  &__header{
    display: flex;
    align-items: flex-start;
  }
  &__title{
    margin: 0;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #0091D2;
    width: 361px;
    min-width: 361px;
    margin-right: 20px;
  }
  &__text{
    width: 100%;
    font-size: 16px;
    line-height: 130%;
    color: #2E2E2E;
  }
  &__wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 55px;
  }
  &__block{
    width: calc(50% - 16px);
    padding: 10px;
    &_header{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &_title{
      font-weight: 600;
      font-size: 32px;
      line-height: 130%;
      color: #000000;
      margin-bottom: 8px;
    }
    &_link{
      font-weight: 600;
      font-size: 13px;
      line-height: 130%;
      color: #1B8FD0;
      text-decoration: none;
    }
    &_text{
      margin-top: 24px;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      color: #000000;
    }
  }
}


@media screen and (max-width: 1200px){
  .partners{
    min-height: 750px;
  }
}

@media screen and (max-width: 991px){
  .partners{
    height: calc(100vh - 420px);
    min-height: 1020px;
    &__title{
      font-size: 32px;
    }
    &__wrapper{
      flex-direction: column;
      margin-top: 30px;
    }
    &__block{
      width: 100%;
      margin-bottom: 30px;
      padding: 0;
    }
  }
}

@media screen and (max-width: 768px){
  .partners{
    height: auto;
    padding-top: 40px;
    &__header{
      flex-direction: column;
    }
    &__title{
      width: 100%;
      min-width: auto;
      margin-bottom: 20px;
    }
    &__block{
      &_header{
        flex-direction: column-reverse;
        align-items: flex-start;
      }
      &_title{
        font-size: 28px;
        margin-top: 24px;
      }
    }
  }
}