.server{
  padding: 79px 0 150px 0;
  overflow: hidden;
  &__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 3;
  }
  &__content{
    position: relative;
    &::before{
      content: '';
      position: absolute;
      top: -60px;
      left: -52px;
      width: 200vw;
      height: calc(100% + 106px);
      background: #E5EEF8;
      border-radius: 20px 0px 0px 20px;
    }
  }
  &__title{
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #2E2E2E;
    width: calc(50% - 20px);
    span{
      color: #0091D2;
    }
  }
  &__descr{
    width: calc(50% - 2px);
    font-size: 16px;
    line-height: 130%;
    color: #2E2E2E;
  }
  &__image{
    width: 100%;
    margin-top: 38px;
    position: relative;
    z-index: 3;
  }
}


@media screen and (max-width: 991px){
  .server{
    &__title{
      font-size: 38px;
    }
  }
}

@media screen and (max-width: 768px){
  .server{
    padding: 35px 0 77px 0;
    &__content{
      &::before{
        left: -200px;
      }
    }
    &__wrapper{
      flex-direction: column;
      align-items: flex-start;
    }
    &__title{
      font-size: 28px;
      width: 100%;
    }
    &__descr{
      width: 100%;
      margin-top: 16px;
    }
  }
}