.thank{
  padding: 19px 0 80px 0;
  height: calc(100vh - 285px);
  min-height: 613px;
  &__wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__left{
    width: 50%;
    padding-top: 70px;
  }
  &__right{
    width: 45%;
    img{
      width: 100%;
    }
  }
  &__title{
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #0091D2;
    margin-bottom: 20px;
  }
  &__descr{
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    color: #0091D2;
  }
  &__number{
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #2E2E2E;
  }
}

@media screen and (max-width: 991px){
  .thank{
    min-height: 750px;
    height: calc(100vh - 420px);
    &__wrapper{
      flex-direction: column-reverse;
      margin-top: 19px;
    }
    &__right{
      margin: 0 auto;
    }
    &__left{
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px){
  .thank{
    min-height: 553px;
    height: calc(100vh - 687px);
    &__right{
      width: 172px;
    }
    &__title{
      font-size: 24px;
      line-height: 120%;
    }
    &__descr{
      font-size: 20px;
    }
    &__number{
      font-size: 20px;
    }
  }
}