.header{
  padding: 8px 0;
  &__shadow{
    box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.1);
  }
  &__list{
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style-type: none;
    li{
      margin-right: 32px;
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      color: #1C475C;
      position: relative;
      cursor: pointer;
      &:last-child{
        margin-right: 0;
      }
      a{
        color: #1C475C;
        text-decoration: none;
        &:active,&:focus,&:visited{
          color: #1C475C;
          text-decoration: none; 
        }
        &:after{
          content: '';
          height: 2px;
          bottom: -5px;
          left: 50%;
          background: #0091D2;
          position: absolute;
          right: 50%;
          transition: .2s ease-in-out;
        }
        &:hover{
          &:after{
            left: 0;
            right: 0;
          }
        }
      }
    }
  }
  &__btns{
    button{
      &:first-child{
        margin-right: 10px;
      }
    }
  }
  &__menu{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &_logo{
      display: none;
    }
  }
  &__logoMob{
    display: none;
  }
  &__user{
    display: flex;
    align-items: center;
    &_picture{
      width: 41px;
      height: 41px;
      border-radius: 50%;
      background: #1C475C;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 7px;
    }
    &_name{
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      color: #1C475C;
    }
    &_logout{
      cursor: pointer;
      padding: 5px;
      margin: 3px 0 0 20px;
    }
    &_main{
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
    }
    &_menu{
      position: absolute;
      top: 45px;
      right: 0;
      background: #FFFFFF;
      border: 1px solid #0091D2;
      box-shadow: 0px 23px 30px -21px rgba(25, 119, 204, 0.25);
      border-radius: 10px;
      padding: 20px 0;
      width: 343px;
      z-index: 5;
      opacity: 0;
      transform: translateY(-100px);
      visibility: hidden;
      transition: .2s linear;
      &_active{
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        transition: .2s linear;
      }
    }
    &_item{
      display: flex;
      align-items: center;
      position: relative;
      padding: 4px 10px;
      margin-bottom: 14px;
      transition: .2s ease-in-out;
      color: #000000;
      text-decoration: none;
      &:active,&:focus,&:hover{
        color: #000000;
        text-decoration: none;
      }
      svg{
        margin-right: 6px;
      }
      &:last-child{
        margin-bottom: 0;
      }
      &_active{
        background: #E5EEF8;
        border-left: 3px solid #0091D2;
        transition: .2s ease-in-out;
      }
      &:hover{
        background: #E5EEF8;
        transition: .2s ease-in-out;
      }
    }
  }
  &__activeLink{
    color: #0091D2!important;
    &:after{
      left: 0!important;
      right: 0!important;
    }
  }
  &__right{
    display: flex;
    align-items: center;
  }
  &__switcher{
    position: relative;
  }
  &__lang{
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 8px;
    cursor: pointer;
    &_text{
      margin: 0 6px;
      font-weight: 500;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 130%;
      color: #1C475C;
    }
  }
  &__dropdown{
    opacity: 0;
    transform: translateY(-100px) scaleY(0);
    visibility: hidden;
    position: absolute;
    top: 30px;
    left: -20px;
    width: 106px;
    padding: 10px 0;
    background: #FFFFFF;
    border: 1px solid #0091D2;
    z-index: 3;
    box-shadow: 0px 23px 30px -21px rgba(25, 119, 204, 0.25);
    border-radius: 10px;
    transition: .2s ease-in-out;
    &_element{
      display: flex;
      align-items: center;
      padding: 4px 12px;
      cursor: pointer;
      transition: .2s ease-in-out;
      div{
        margin: 0 14px 0 6px;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        color: #1C475C;
      }
      img{
        &:last-child{
          opacity: 0;
          visibility: hidden;
          margin-left: auto;
          display: block;
          transition: .2s ease-in-out;
        }
      }
      &_active{
        background: #E5EEF8;
        border-left: 4px solid #0091D2;
        transition: .2s ease-in-out;
        img{
          &:last-child{
            opacity: 1;
            visibility: visible;
            transition: .2s ease-in-out;
          }
        }
      }
      &:hover{
        background: #E5EEF8;
        transition: .2s ease-in-out;
      }
    }
    &_active{
      opacity: 1;
      transform: translateY(0) scaleY(1);
      visibility: visible;
      transition: .2s ease-in-out;
    }
  }
}

.burger {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: none;
  align-items: center;
  padding: 0;
  width: 50px;
  height: 50px;
  position: relative;
  z-index: 6;
  outline: none;
}
.line {
  fill: none;
  stroke: #0091D2;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.opened{
  .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
  .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
  }
  .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
}

@media screen and (max-width: 1200px){
  .header{
    &__btns{
      button{
        width: 85px!important;
      }
    }
  }
}

@media screen and (max-width: 991px){
  .burger{
    display: flex;
  }
  .header{
    &__menu{
      position: fixed;
      top: 0;
      right: 0;
      z-index: 5;
      background: #fff;
      width: 100%;
      height: 100vh;
      flex-direction: column;
      justify-content: center;
      transform: translateX(100%);
      transition: .3s ease-in-out;
      &_active{
        transform: translateX(0);
        transition: .3s ease-in-out;
      }
      &_logo{
        display: block;
      }
    }
    &__logo{
      display: none;
    }
    &__list{
      flex-direction: column;
      li{
        margin: 0 0 32px 0;
      }
    }
    &__logoMob{
      display: block;
      width: 85px;
    }
    &__right{
      flex-direction: column;
    }
    &__lang{
      margin-right: 0;
    }
    &__btns{
      margin-top: 55px;
      display: flex;
      flex-direction: column;
      button{
        width: 125px!important;
        &:first-child{
          margin: 0 0 10px 0;
        }
      }
    }
    &__user{
      &_menu{
        bottom: 0;
        top: auto;
        right: -120px;
      }
    }
    &__dropdown{
      top: auto;
      bottom: 37px;
      transform: translateY(100px) scaleY(0);
      &_active{
        transform: translateY(0) scaleY(1);
      }
    }
  }
}

@media screen and (max-width: 576px){
  .header{
    &__logoMob{
      width: 48px;
    }
    &__btns{
      width: 100%;
      align-items: center;
      button{
        width: 90%!important;
        margin: 0 auto;
      }
      a{
        width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        text-decoration: none;
        button{
          width: 100%!important;
        }
      }
    }
  }
  .burger{
    svg{
      height: 30px;
    }
  }
}

