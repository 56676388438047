$blue: #0091D2;

.certificate{
  padding: 38px 32px 181px 15px;
  width: 100%;
  overflow: auto;
  &__title{
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    color: $blue;
    margin: 0;
  }
  &__wrapper{
    display: flex;
    align-items: flex-start;
    margin-top: 9px;
  }
  &__block{
    border: 1px solid #E9E9E9;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    &_row{
      display: flex;
      align-items: flex-start;
      margin-bottom: 8px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    &_text{
      font-size: 16px;
      line-height: 130%;
      color: #2E2E2E;
    }
    &_left{
      width: 95px;
      min-width: 95px;
      margin-right: 22px;
    }
  }
  &__addCertificate{
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 215px;
    min-width: 215px;
    margin-left: 46px;
    height: 48px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;
    background: $blue;
    box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
    border-radius: 3px;
    border: 1px solid $blue;
    text-decoration: none;
    div{
      margin-left: 10px;
    }
    &:active,
    &:focus{
      outline: none;
      text-decoration: none;
    }
  }
  &__table{
    margin-top: 34px;
    overflow: auto;
    &_header{
      display: flex;
      justify-content: space-between;
      .certificate__action{
        padding-top: 12px;
      }
    }
    &_title{
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      text-transform: capitalize;
      color: #1C475C;
    }
    &_row{
      display: flex;
      justify-content: space-between;
    }
    &_value{
      font-size: 16px;
      line-height: 130%;
      color: #2E2E2E;
      border-bottom: 1px solid #AEAEAE;
    }
  }
  &__type{
    width: 12.9%;
    min-width: 150px;
    display: flex;
    align-items: flex-start;
    padding: 12px 4px 12px 15px;
    min-width: 150px;
    svg{
      min-width: 20px;
      margin-right: 10px;
    }
  }
  &__status{
    width: 12.9%;
    min-width: 120px;
    padding: 12px 4px 12px 0px;
  }
  &__name{
    width: 22.6%;
    padding: 12px 4px 12px 0px;
    min-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__date{
    width: 12.9%;
    padding: 12px 4px 12px 0px;
    min-width: 140px;
  }
  &__id{
    width: 25.8%;
    padding: 12px 4px 12px 0px;
    min-width: 230px;
    &_value{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__action{
    width: 12.9%;
    display: flex;
    align-items: flex-start;
    padding: 0px 12px 12px 0px;
    min-width: 145px;
    button{
      padding: 10px;
      border: none;
      background: transparent;
      outline: none;
      cursor: pointer;
      &:active,
      &:focus,
      &:hover{
        outline: none;
      }
    }
    a{
      padding: 10px;
      text-decoration: none;
      &:active,
      &:focus,
      &:hover{
        text-decoration: none;
      }
    }
  }
  &__empty{
    border: 1px solid #0091D2;
    margin-top: 30px;
    border-radius: 10px;
    height: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div{
      text-align: center;
      font-weight: 500;
      font-size: 21px;
    }
  }
}

@media screen and (max-width: 991px){
  .certificate{
    padding-left: 30px;
    padding-right: 30px;
    &__table{
      &_header{
        display: none;
      }
      &_row{
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #AEAEAE;
        margin-bottom: 16px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    &__type,
    &__status,
    &__name,
    &__date,
    &__id,
    &__action{
      min-width: auto;
      border-bottom: none;
      padding: 0;
    }
    &__type{
      width: 50%;
    }
    &__status{
      width: 50%;
      display: flex;
      justify-content: flex-end;
    }
    &__name{
      padding-bottom: 5px;
      font-size: 13px;
    }
    &__date{
      padding-top: 5px;
      font-size: 10px;
    }
    &__name,
    &__date,
    &__id,
    &__action{
      width: 100%;
    }
    &__name,
    &__id,
    &__action{
      margin-top: 16px;
    }
    &__action{
      justify-content: flex-end;
      padding-bottom: 16px;
      button, a{
        background: #E5EEF8;
        border-radius: 6px;
        margin-left: 8px;
      }

    }
  }
}



@media screen and (max-width: 768px){
  .certificate{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 60px;
    &__wrapper{
      flex-direction: column-reverse;
    }
    &__title{
      font-size: 28px;
    }
    &__addCertificate{
      margin: 21px 0;
      width: 100%;
    }
    &__block{
      padding: 10px;
      &_row{
        flex-direction: column;
      }
      &_left{
        margin: 0 0 4px 0;
      }
    }
  }
}

@media screen and (max-width: 576px){
  .certificate{
    &__table{
      &_row{
        align-items: center;
      }
    }
    &__status{
      div{
        white-space: nowrap;
      }
    }
  }
}