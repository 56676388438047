.token{
  overflow: hidden;
  &__content{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 57px 84px 46px 0;
    &::before{
      content: '';
      position: absolute;
      top: 0px;
      right: 0px;
      width: 300%;
      height: calc(100% + 106px);
      background: #E5EEF8;
      border-radius: 0 20px 20px 0;
    }
  }
  &__title{
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #2E2E2E;
    position: relative;
    z-index: 2;
    span{
      color: #0091D2;
    }
  }
  &__descr{
    margin-top: 36px;
    position: relative;
    z-index: 3;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #2E2E2E;
  }
  &__image{
    position: relative;
    z-index: 2;
    margin-left: 36px;
  }
}

@media screen and (max-width: 991px){
  .token{
    &__title{
      font-size: 38px;
    }
  }
}

@media screen and (max-width: 768px){
  .token{
    &__content{
      padding: 46px 0 40px 0;
      flex-direction: column-reverse;
      align-items: flex-start;
      &::before{
        right: -200px;
      }
    }
    &__image{
      margin: 0;
      width: 100px;
    }
    &__title{
      margin-top: 24px;
      font-size: 28px;
    }
  }
}