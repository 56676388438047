.certificate{
  padding: 25px 0 31px 0;
  overflow: hidden;
  &__title{
    margin: 20px 0 0 0;
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #0091D2;
  }
  &__wrapper{
    display: flex;
    align-items: flex-start;
    margin-top: 31px;
    &_bottom{
      display: flex;
      align-items: flex-start;
      margin-top: 16px;
    }
  }
  &__left{
    width: 65%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &_info{
      width: calc(50% - 20px);
      margin-left: 50px;
    }
    &_img{
      width: 350px;
      position: relative;
    }
  }
  &__right{
    width: calc(35% - 20px);
    position: relative;
    &::after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      background: #E5EEF8;
      border-radius: 20px 0 0 20px;
    }
    &_container{
      position: relative;
      z-index: 2;
      padding: 21px 33px 26px 33px;
    }
  }
  &__row{
    margin-bottom: 24px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  &__label{
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #1C475C;
  }
  &__answer{
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #2E2E2E;
  }
  &__client{
    &_img{
      width: 283px;
      height: 101px;
      margin-bottom: 16px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__status{
    padding: 11px 20px;
    display: inline-flex;
    align-items: center;
    background: #B0FAB7;
    border-radius: 10px;
    margin-bottom: 24px;
    span{
      margin-left: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 10px;
      text-transform: capitalize;
      color: #30A03B;
    }
  }
  &__download{
    width: 100%;
    background: #0091D2;
    box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
    border-radius: 3px;
    cursor: pointer;
    padding: 14px 32px;
    border: none;
    display: inline-flex;
    align-items: center;
    span{
      margin-left: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      color: #FFFFFF;        
    }
    &_qr{
      width: 100%;
      background: #0091D2;
      box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
      border-radius: 3px;
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      text-align: center;
      color: #FFFFFF;
      outline: none;
      cursor: pointer;
      border: 1px solid #0091D2;
      margin-left: 19px;
      padding: 14px 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 14px auto 0 auto;
    }
  }
  &__downloadMob{
    display: none;
  }
  &__bottom{
    &_right{
      margin-left: 75px;
    }
    &_title{
      font-weight: 700;
      font-size: 32px;
      line-height: 130%;
      color: #1C475C;
    }
  }
  &__hash{
    display: flex;
    align-items: center;
    cursor: pointer;
    &_input{
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #2E2E2E;
      max-width: 709px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 7px;
      border: none;
      outline: none;
      width: 100%;
      padding-right: 10px;
      pointer-events: none;
      &:active,&:focus{
        outline: none;
      }
    }
  }
  &__share{
    margin-top: 24px;
    width: 740px;
    display: flex;
    align-items: flex-start;
  }
  &__block{
    width: calc(50% - 4px);
    &:last-child{
      margin-left: auto;
    }
    &_title{
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #1C475C;
      margin-bottom: 4px;
    }
    &_wrapper{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      a{
        margin-left: 24px;
        &:first-child{
          margin-left: 0;
        }
      }
    }
    &_link{
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      text-decoration-line: underline;
      color: #2E2E2E;
      max-width: 100%;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &:last-child{
      .certificate__block_title{
        text-align: right;
      }
    }
  }
  &__tab{
    display: none;
  }
  &__print{
    position: absolute;
    top: 7px;
    left: 10px;
    width: 100px;
  }
  &__seal{
    position: absolute;
    bottom: -9px;
    right: -34px;
    width: 130px;
  }
  &__public{
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_status{
      padding: 10px 20px;
      display: flex;
      align-items: center;
      border-left: 4px solid transparent;
      width: 100%;
      transition: .2s ease-in-out;
      div{
        margin-left: 12px;
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        letter-spacing: -0.02em;
        transition: .2s ease-in-out;
      }
    }
    &_open{
      border-color: #30A03B;
      background: #D1EEEB;
      transition: .2s ease-in-out;
      div{
        color: #30A03B;
        transition: .2s ease-in-out;
      }
    }
    &_close{
      border-color: #EA5455;
      background: rgba(234, 84, 85, 0.25);
      div{
        color: #EA5455;
      }
    }
  }
  &__button{
    outline: none;
    padding: 14px 32px;
    background: #0091D2;
    box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
    border-radius: 3px;
    border: 1px solid #0091D2;
    display: inline-flex;
    align-items: center;
    margin-left: 27px;
    cursor: pointer;
    div{
      margin-left: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      color: #FFFFFF;
      white-space: nowrap;
    }
    &_public{
      background: #fff;
      div{
        color: #0091D2;
      }
    }
  }

}

.info{
  padding: 26px 0 52px 0;
  background: #E5EEF8;
  &__wrapper{
    display: flex;
    align-items: flex-start;
  }
  &__left{
    width: 350px;
    min-width: 350px;
    position: relative;
  }
  &__right{
    margin-left: 37px;
    width: 100%;
    &_label{
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #1C475C;
      margin-bottom: 4px;
    }
    &_value{
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #2E2E2E;
      img{
        margin-right: 4px;
      }
    }
    &_row{
      margin-bottom: 24px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  &__title{
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    color: #1C475C;
    margin-bottom: 15px;
    position: relative;
    z-index: 3;
  }
  &__squares{
    position: relative;
    z-index: 3;
  }
  &__bg{
    position: absolute;
    top: 55px;
    left: 0;
    width: 100%;
    z-index: 2;
  }
  &__embed{
    &_wrapper{
      margin-top: 38px;
    }
    &_block{
      margin-bottom: 24px;
      display: flex;
      align-items: flex-start;
      &:last-child{
        margin-bottom: 0;
      }
    }
    &_info{
      margin-left: 9px;
      width: 100%;
    }
    &_title{
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #1C475C;
      margin-bottom: 4px;
    }
    &_descr{
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #2E2E2E;
    }
    &_link{
      margin-top: 10px;
      padding: 11px 37px 11px 10px;
      background: #FFFFFF;
      border-radius: 2px;
      position: relative;
      textarea{
        width: 100%;
        height: 24px;
        resize: none;
        outline: none;
        border: none;
      }
    }
    &_image{
      display: block;
      margin-top: 6px;
    }
    &_copy{
      cursor: pointer;
      position: absolute;
      top: 11px;
      right: 8px;
    }
  }
}


@media screen and (max-width: 1200px){
  .certificate{
    &__left{
      &_img{
        width: 300px;
        min-width: 300px;
        img{
          width: 100%;
        }
        .certificate__print{
          width: 100px;
        }
        .certificate__seal{
          width: 130px;
          right: -26px;
        }
      }
      &_info{
        margin-left: 34px;
      }
    }

    &__client{
      &_img{
        width: 250px;
        height: auto;
      }
    }
    &__bottom{
      &_left{
        width: 300px;
        min-width: 300px;
      }
      &_right{
        margin-left: 30px;
        width: calc(100% - 332px);
      }
    }
    &__download{
      margin-left: 0;
      width: 100%;
      &_qr{
        margin-left: 0;
        width: 100%;
      }
    }
    &__share{
      width: 100%;
    }
  }
  .info{
    &__left{
      width: 300px;
      min-width: 300px;
    }
    &__right{
      width: calc(100% - 387px);
      margin-left: 33px;
      &_row{
        .info__right_value{
          display: block;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:first-child{
          .info__right_value{
            display: flex;
          } 
        }
      }
    }
    &__embed{
      &_link{
        textarea{
          height: 36px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px){
  .certificate{
    &__wrapper{
      flex-wrap: wrap;
      &_bottom{
        .certificate__download{
          display: none;
        }
      }
    }
    &__left{
      &_info{
        display: none;
        width: 100%;
        margin-left: 0;
      }
      &_img{
        margin: 0 auto;
        .certificate__seal{
          bottom: 8px;
          right: -63px;
        }
      }
    }
    &__right{
      width: 100%;
      display: none;
      &::after{
        display: none;
      }
      &_container{
        padding: 0;
      }
    }
    &__status{
      margin-bottom: 0;
      position: absolute;
      top: 5px;
      right: 0;
    }
    &__downloadMob{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex-direction: column;
    }
    &__download{
      width: 350px;
      display: flex;
      justify-content: center;
      &_qr{
        width: 350px;
        margin: 16px 0 0 0;
      }
    }
    &__bottom{
      &_left{
        display: none;
      }
      &_right{
        margin-left: 0;
        width: 100%;
      }
      &_title{
        font-size: 24px;
      }
    }
    &__tab{
      display: flex;
      margin: 24px 0;
      width: 100%;
      &_item{
        width: 50%;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #E5EEF8;
        font-size: 20px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C475C;
        cursor: pointer;
        &_active{
          border-bottom: 2px solid #0091D2;
          background: #E5EEF8;
          font-weight: 600;
          font-size: 20px;
          line-height: 130%;
          letter-spacing: -0.02em;
          color: #0091D2;
        }
      }
      &_active{
        display: block;
      }
    }
  }
  .info{
    &__embed{
      &_copy{
        top: 7px;
        right: 5px;
      }
      &_link{
        textarea{
          height: 71px;
        }
      }
    }
  }
}


@media screen and (max-width: 768px){
  .certificate{
    &__status{
      right: 10px;
    }
    &__title{
      font-weight: 600;
      font-size: 32px;
      line-height: 120%;
      letter-spacing: -0.03em;
    }
    &__share{
      flex-direction: column;
    }
    &__block{
      width: 100%;
      &:last-child{
        margin-top: 24px;
        .certificate__block_title{
          text-align: left;
        }
      }
      &_wrapper{
        justify-content: flex-start;
      }
    }
    &__public{
      flex-direction: column;
      button{
        width: 100%;
        margin: 27px 0 0 0;
        justify-content: center;
      }
    }
  }
  .info{
    &__wrapper{
      flex-direction: column;
    }
    &__left{
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    &__bg{
      display: none;
    }
    &__title{
      font-size: 24px;
      line-height: 130%;
      margin-right: 30px;
    }
    &__right{
      margin-left: 0;
      width: 100%;
      &_row{
        .info__right_value{
          word-break: break-all;
          white-space: break-spaces;
        }
      }
    }
    &__embed{
      &_link{
        textarea{
          height: 48px;
        }
      }
    }
  }
}

@media screen and (max-width: 576px){
  .certificate{
    &__left{
      &_img{
        .certificate__seal{
          width: 100px;
          right: 0;
        }
      }
    }
    &__download{
      width: 100%;
      &_qr{
        width: 100%;
      }
    }
  }
  .info{
    &__embed{
      &_block{
        & > img {
          display: none;
        }
      }
      &_link{
        padding-top: 20px;
      }
      &_info{
        margin-left: 0;
      }
    }
  }
}


@media screen and (max-width: 350px){
  .info{
    &__embed{
      &_link{
        textarea{
          height: 71px;
        }
      }
    }
  }
}