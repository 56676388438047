.contact{
  padding: 60px 0 37px 0;
  overflow: hidden;
  &__left{
    width: calc(50% - 20px);
    position: relative;
    background: url('../../../img/contactBg.png');
    background-repeat: no-repeat;
    background-position: 0 70px;
  }
  &__title{
    margin: 0;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #0091D2;
    margin: 0;
  }
  &__wrapper{
    display: flex;
    justify-content: space-between;
  }
  &__form{
    position: relative;
    width: 50%;
    padding-left: 58px;
    &::before{
      content: "";
      width: 100vw;
      height: calc(100% + 17px);
      position: absolute;
      top: -24px;
      left: 0;
      background: #0091D2;
      border-radius: 20px 0 0 20px;
      z-index: 2;
    }
    &_form{
      label{
        position: relative;
        z-index: 3;
        margin-bottom: 16px;
        display: block;
        span{
          padding-left: 16px;
          font-weight: 500;
          font-size: 16px;
          line-height: 130%;
          color: #FFFFFF;
          display: block;
          margin-bottom: 8px;
        }
        input,
        textarea{
          width: 100%;
          outline: none;
          border: 1px solid #AEAEAE;
          border-radius: 10px;
          padding: 16px;
          font-size: 16px;
          line-height: 130%;
          color: #2E2E2E;
          &::-webkit-input-placeholder { 
            color: #AEAEAE;
          }
          &::-moz-placeholder { 
            color: #AEAEAE;
          }
          &:-ms-input-placeholder {
            color: #AEAEAE;
          }
          &:-moz-placeholder { 
            color: #AEAEAE;
          }
        }
        textarea{
          resize: none;
          height: 238px;
        }
      }
      button{
        position: relative;
        z-index: 3;
        border: none;
        width: 138px;
        height: 48px;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px rgba(25, 119, 204, 0.2);
        border-radius: 3px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        margin-left: auto;
        div{
          margin-left: 10px;
          font-weight: 500;
          font-size: 16px;
          line-height: 120%;
          color: #0091D2;
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .contact{
    display: none;
  }
}